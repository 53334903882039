import React from 'react';
import styled from 'styled-components';
import i18n from '../../../i18n';
import { DynamicComponent } from '../../components/dynamic-component';

const Container = styled.div`
    max-width: 1000px;
    margin: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
`;

export default ({ language }) => {
    const content = i18n[language || 'en'];

    return (
        <Container>
            {content.pages.privacy.map(o => (
                <DynamicComponent options={o} />
            ))}
        </Container>
    );
};
